import React from 'react';
import { Box, useTheme } from '@mui/material';

import {
  ButtonProps,
  Dialog,
  InformationBlock,
  Page,
  TableTabs,
  Text,
  TextField,
} from 'components';
import { downloadBlob, formatPrice } from 'core/shared';
import { useToast } from 'core/toast/hooks';
import { useNavigate } from 'hooks/useNavigate';
import { useParams } from 'hooks/useParams';
import { useSearchParams } from 'hooks/useSearchParams';
import { useTranslations } from 'hooks/useTranslations';

import { getSuperAdminDashboardUrl } from 'config/shared';
import { CustomString } from 'config/translations';
import {
  OrganisationAfas,
  OrganisationCommissions,
  OrganisationMerchantCodes,
  OrganisationProviders,
} from 'pages/components';
import { UseMutation, UseQuery } from 'useQuery';
import { Product } from 'useQuery/types';

function OrganisationPage() {
  const theme = useTheme();
  const [loading, setLoading] = React.useState<
    | 'afas'
    | 'merchantCategoryCodes'
    | 'organisation'
    | 'contact'
    | 'invoicing'
    | 'commissions'
    | false
  >(false);

  const { navigate } = useNavigate();

  const { searchParams, setSearchParams } = useSearchParams('organisation');

  const [tabIndex, setTabIndex] = React.useState(0);

  React.useEffect(() => {
    if (searchParams.tab) {
      setTabIndex(searchParams.tab);
    }
  }, [searchParams]);

  const { addToast } = useToast();

  const [openResendInvitesTitle, setOpenResendInvitesTitle] = React.useState(false);
  const [openAddUser, setOpenAddUser] = React.useState(false);

  const [addUserFirstName, setAddUserFirstName] = React.useState('');
  const [addUserLastName, setAddUserLastName] = React.useState('');
  const [addUserPhoneNumber, setAddUserPhoneNumber] = React.useState('');
  const [addUserEmail, setAddUserEmail] = React.useState('');

  const [name, setName] = React.useState('');
  const [CoCNumber, setCoCNumber] = React.useState('');
  const [VAT, setVAT] = React.useState('');

  const [stripeCustomerId, setStripeCustomerId] = React.useState('');
  const [communityId, setCommunityId] = React.useState<string>('');

  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [phoneNumber, setPhoneNumber] = React.useState('');
  const [reservationsNotificationsEmail, setReservationsNotificationsEmail] = React.useState('');

  const [paymentToEmployee, setPaymentToEmployee] = React.useState(false);
  const [active, setActive] = React.useState(true);
  const [bookingNote, setBookingNote] = React.useState(false);
  const [emissionReportAccess, setEmissionReportAccess] = React.useState(false);
  const [travelCard, setTravelCard] = React.useState(false);
  const [kilometerRegistration, setKilometerRegistration] = React.useState(false);
  const [travelCardsByDefault, setTravelCardsByDefault] = React.useState(false);
  const [reservationsNotifications, setReservationsNotifications] = React.useState(false);
  const [monthlyInvoicedProducts, setMonthlyInvoicedProducts] = React.useState<Product['id'][]>([]);
  const [outsideOfficeHours, setOutsideOfficeHours] = React.useState(false);
  const [topSpendersEnabled, setTopSpendersEnabled] = React.useState(false);
  const [employeeDashboardEnabled, setEmployeeDashboardEnabled] = React.useState(false);

  const { id } = useParams('organisation');

  const translations = useTranslations('organisationPage');

  const { data: VATData } = UseQuery.VAT();
  const { data: organisationData } = UseQuery.organisation({ id });
  const { data: productData } = UseQuery.products({ page: 1, limit: 10000 });
  const { data: communitiesData } = UseQuery.communities({ page: 1, limit: 10000 });

  const { data: hasBulkInvites } = UseQuery.hasBulkInvites({ id });

  const { mutateAsync: mutateAsyncEditOrganisation } = UseMutation.editOrganisation();

  const { mutateAsync: mutateBulkInviteUpload } = UseMutation.bulkInviteUpload();

  const { mutateAsync: mutateAsyncAddSuperAdminsToOrganisation } =
    UseMutation.addSuperAdminsToOrganisation();

  const { mutateAsync: mutateAsyncExportOrganisation, isLoading: isLoadingEditExportOrganisation } =
    UseMutation.exportOrganisation();

  const { mutateAsync: mutateAsyncResendInvites, isLoading: isLoadingResendInvites } =
    UseMutation.resendInvites();

  const { mutateAsync: mutateAsyncDownloadBulkInvites } = UseMutation.downloadBulkInvites();

  const { mutateAsync: mutateAsyncAddOrganisation, isLoading: isLoadingAddOrganisation } =
    UseMutation.addOrganisation();

  const { mutateAsync: mutateAsyncAddUser, isLoading: isLoadingAddUser } = UseMutation.addUser();

  const hasNewOrganisation =
    id !== 'new' &&
    (name !== (organisationData?.result.name ?? '') ||
      CoCNumber !== (organisationData?.result.coc_number ?? '') ||
      VAT !== (organisationData?.result.vat_number ?? '') ||
      stripeCustomerId !== (organisationData?.result.stripe_customer_id ?? '') ||
      communityId !== (organisationData?.result.community?.id ?? ''));

  const hasNewContact =
    id !== 'new' &&
    (firstName !== organisationData?.result.contact?.first_name ||
      lastName !== organisationData?.result.contact?.last_name ||
      email !== organisationData?.result.contact?.email ||
      phoneNumber !== organisationData?.result.contact?.phone_number);

  const hasNewInvoicing =
    id !== 'new' &&
    (paymentToEmployee !== !!organisationData?.result.delegate_payment_to_employee ||
      active !== !!organisationData?.result.active ||
      bookingNote !== !!organisationData?.result.booking_note_enabled ||
      emissionReportAccess !== !!organisationData?.result.emission_report_enabled ||
      travelCard !== !!organisationData?.result.allow_payment_instruments ||
      kilometerRegistration !== !!organisationData?.result.kilometer_registration_enabled ||
      travelCardsByDefault !== !!organisationData?.result.enable_travel_cards_by_default ||
      reservationsNotifications !== !!organisationData?.result.reservations_notifications_enabled ||
      outsideOfficeHours !== !!organisationData?.result.outside_office_hours ||
      topSpendersEnabled !== !!organisationData?.result.top_spenders_enabled ||
      employeeDashboardEnabled !== !!organisationData?.result.employee_dashboard_enabled ||
      JSON.stringify(monthlyInvoicedProducts.sort((a, b) => parseInt(a) - parseInt(b))) !==
        JSON.stringify(
          (organisationData?.result.products ?? [])
            .map(({ id }) => id)
            .sort((a, b) => parseInt(a) - parseInt(b)),
        ));

  React.useEffect(() => {
    const result = organisationData?.result;
    const firstTime = !name;
    if (!result || (!firstTime && !loading)) {
      return;
    }

    const {
      name: organisationName,
      coc_number,
      vat_number,
      stripe_customer_id,
      community,
      active,
      delegate_payment_to_employee,
      emission_report_enabled,
      enable_travel_cards_by_default,
      allow_payment_instruments,
      booking_note_enabled,
      reservations_notifications_enabled,
      reservations_notifications_email,
      kilometer_registration_enabled,
      outside_office_hours,
      top_spenders_enabled,
      contact,
      products,
      employee_dashboard_enabled,
    } = result;

    if (firstTime || loading === 'organisation') {
      setName(organisationName ?? '');
      setCoCNumber(coc_number ?? '');
      setVAT(vat_number ?? '');
      setStripeCustomerId(stripe_customer_id ?? '');
      setCommunityId(community?.id ?? '');

      setLoading(false);
    }

    if (firstTime || loading === 'contact') {
      setFirstName(contact?.first_name ?? '');
      setLastName(contact?.last_name ?? '');
      setEmail(contact?.email ?? '');
      setPhoneNumber(contact?.phone_number ?? '');
      setReservationsNotificationsEmail(reservations_notifications_email ?? '');

      setLoading(false);
    }

    if (firstTime || loading === 'invoicing') {
      setPaymentToEmployee(delegate_payment_to_employee);
      setActive(active);
      setBookingNote(booking_note_enabled);
      setEmissionReportAccess(emission_report_enabled);
      setTravelCard(allow_payment_instruments);
      setKilometerRegistration(kilometer_registration_enabled);
      setTravelCardsByDefault(enable_travel_cards_by_default);
      setReservationsNotifications(reservations_notifications_enabled);
      setOutsideOfficeHours(outside_office_hours);
      setTopSpendersEnabled(top_spenders_enabled);
      setEmployeeDashboardEnabled(employee_dashboard_enabled);
      setMonthlyInvoicedProducts((products ?? []).map(({ id }) => id));

      setLoading(false);
    }
  }, [organisationData]);

  const footer: ButtonProps[] = [
    {
      label: translations.cancel,
      variant: 'outlined',
      onClick: () => {
        navigate({
          name: 'organisations',
          id: undefined,
          searchParams: { page: 1, q: '' },
          metaKey: false,
        });
      },
    },
    {
      label: id === 'new' ? translations.save : translations.update,
      onClick: async () => {
        try {
          const { result } = await mutateAsyncAddOrganisation({
            name,
            paymentToEmployee,
            bookingNote,
            emissionReportAccess,
            kilometerRegistration,
            CoCNumber,
            VAT,
            stripeCustomerId,
            communityId,
            contact: {
              firstName,
              lastName,
              email,
              phoneNumber,
            },
          });

          if (result.id) {
            navigate({
              name: 'organisation',
              id: result.id,
              searchParams: { tab: 0 },
              metaKey: false,
            });
          } else {
            navigate({
              name: 'organisations',
              id: undefined,
              searchParams: { page: 1, q: '' },
              metaKey: false,
            });
          }
        } catch (error) {
          // Do nothing
        }
      },
      disabled:
        !name ||
        !CoCNumber ||
        !VAT ||
        !stripeCustomerId ||
        !communityId ||
        !firstName ||
        !lastName ||
        !email ||
        !phoneNumber,
      loading: isLoadingAddOrganisation,
    },
  ];

  const DetailsContent = (
    <>
      <InformationBlock<string>
        title={translations.organisation}
        footer={[
          {
            variant: 'outlined',
            label: translations.restore,
            onClick: () => {
              const { name, coc_number, vat_number, stripe_customer_id, community } =
                organisationData?.result ?? {};

              setName(name ?? '');
              setCoCNumber(coc_number ?? '');
              setVAT(vat_number ?? '');
              setStripeCustomerId(stripe_customer_id ?? '');
              setCommunityId(community?.id ?? '');
            },
            disabled: !hasNewOrganisation,
          },
          {
            label: translations.save,
            onClick: async () => {
              setLoading('organisation');
              try {
                await mutateAsyncEditOrganisation({
                  id: organisationData?.result.id ?? '',
                  data: {
                    active: organisationData?.result.active ?? true,
                    coc_number: CoCNumber,
                    contact: {
                      email: organisationData?.result.contact?.email ?? '',
                      first_name: organisationData?.result.contact?.first_name ?? '',
                      last_name: organisationData?.result.contact?.last_name ?? '',
                      phone_number: organisationData?.result.contact?.phone_number ?? '',
                    },
                    name,
                    vat_number: VAT,
                    stripe_customer_id: stripeCustomerId,
                    community_id: communityId,
                    products: (organisationData?.result.products ?? []).map(({ id }) => id),
                    booking_note_enabled: !!organisationData?.result.booking_note_enabled,
                    transaction_note_enabled: !!organisationData?.result.transaction_note_enabled,
                    allow_payment_instruments: !!organisationData?.result.allow_payment_instruments,
                    kilometer_registration_enabled:
                      !!organisationData?.result.kilometer_registration_enabled,
                    emission_report_enabled: !!organisationData?.result.emission_report_enabled,
                    reservations_notifications_enabled:
                      !!organisationData?.result.reservations_notifications_enabled,
                    delegate_payment_to_employee:
                      !!organisationData?.result.delegate_payment_to_employee,
                    enable_travel_cards_by_default:
                      !!organisationData?.result.enable_travel_cards_by_default,
                    outside_office_hours: !!organisationData?.result.outside_office_hours,
                    top_spenders_enabled: !!organisationData?.result.top_spenders_enabled,
                    employee_dashboard_enabled:
                      !!organisationData?.result.employee_dashboard_enabled,
                  },
                });

                addToast({ title: translations.editOrganisationSuccess, severity: 'success' });
              } catch (error) {
                setLoading(false);
              }
            },
            disabled: !hasNewOrganisation,
            loading: loading === 'organisation',
          },
        ]}
        items={[
          {
            label: translations.id,
            data: {
              value: organisationData?.result.id ?? '',
            },
            type: 'text',
          },
          {
            label: translations.name,
            data: {
              value: name,
              onChange: setName,
              onEnter: () => {},
              required: true,
            },
            type: 'editName',
          },
          {
            label: translations.KVK,
            data: {
              value: CoCNumber,
              onChange: setCoCNumber,
              onEnter: () => {},
              required: true,
            },
            type: 'editText',
          },
          {
            label: translations.VAT,
            data: {
              value: VAT,
              onChange: setVAT,
              onEnter: () => {},
              required: true,
            },
            type: 'editText',
          },
          {
            label: translations.stripeCustomerId,
            data: {
              value: stripeCustomerId,
              onChange: setStripeCustomerId,
              onEnter: () => {},
              required: true,
            },
            type: 'editText',
          },
          {
            label: translations.communityId,
            data: {
              label: translations.communityId,
              value: communityId,
              options: (communitiesData?.result ?? []).map(({ id, name }) => ({
                id: id,
                label: name as CustomString,
              })),
              onChange: setCommunityId,
              mode: 'single',
            },
            type: 'select_1',
          },
        ]}
      />
      <Box sx={{ height: theme.spacing(2) }} />
      <InformationBlock
        title={translations.contact}
        footer={[
          {
            variant: 'outlined',
            label: translations.restore,
            onClick: () => {
              const { contact } = organisationData?.result ?? {};

              setFirstName(contact?.first_name ?? '');
              setLastName(contact?.last_name ?? '');
              setEmail(contact?.email ?? '');
              setPhoneNumber(contact?.phone_number ?? '');
            },
            disabled: !hasNewContact,
          },
          {
            label: translations.save,
            onClick: async () => {
              setLoading('contact');
              try {
                await mutateAsyncEditOrganisation({
                  id: organisationData?.result.id ?? '',
                  data: {
                    coc_number: organisationData?.result.coc_number ?? '',
                    contact: {
                      email: email,
                      first_name: firstName,
                      last_name: lastName,
                      phone_number: phoneNumber,
                    },
                    active: organisationData?.result.active ?? true,
                    name: organisationData?.result.name ?? '',
                    vat_number: organisationData?.result.vat_number ?? '',
                    stripe_customer_id: organisationData?.result.stripe_customer_id ?? '',
                    community_id: organisationData?.result.community?.id ?? '',
                    products: (organisationData?.result.products ?? []).map(({ id }) => id),
                    booking_note_enabled: !!organisationData?.result.booking_note_enabled,
                    transaction_note_enabled: !!organisationData?.result.transaction_note_enabled,
                    allow_payment_instruments: !!organisationData?.result.allow_payment_instruments,
                    kilometer_registration_enabled:
                      !!organisationData?.result.kilometer_registration_enabled,
                    emission_report_enabled: !!organisationData?.result.emission_report_enabled,
                    reservations_notifications_enabled:
                      !!organisationData?.result.reservations_notifications_enabled,
                    delegate_payment_to_employee:
                      !!organisationData?.result.delegate_payment_to_employee,
                    enable_travel_cards_by_default:
                      !!organisationData?.result.enable_travel_cards_by_default,
                    outside_office_hours: !!organisationData?.result.outside_office_hours,
                    top_spenders_enabled: !!organisationData?.result.top_spenders_enabled,
                    employee_dashboard_enabled:
                      !!organisationData?.result.employee_dashboard_enabled,
                  },
                });

                addToast({ title: translations.editContactSuccess, severity: 'success' });
              } catch (error) {
                setLoading(false);
              }
            },
            disabled: !hasNewContact,
            loading: loading === 'contact',
          },
        ]}
        items={[
          {
            label: translations.firstName,
            data: {
              value: firstName,
              onChange: setFirstName,
              onEnter: () => {},
              required: true,
            },
            type: 'editName',
          },
          {
            label: translations.lastName,
            data: {
              value: lastName,
              onChange: setLastName,
              onEnter: () => {},
              required: true,
            },
            type: 'editName',
          },
          {
            label: translations.email,
            data: {
              value: email,
              onChange: setEmail,
              onEnter: () => {},
              required: true,
            },
            type: 'editEmail',
          },
          {
            label: translations.phoneNumber,
            data: {
              value: phoneNumber,
              onChange: setPhoneNumber,
              onEnter: () => {},
              required: false,
            },
            type: 'editPhoneNumber',
          },
          {
            label: translations.reservationsNotificationsEmail,
            data: {
              value: reservationsNotificationsEmail,
              onChange: setReservationsNotificationsEmail,
              onEnter: () => {},
              required: false,
              disabled: true,
            },
            type: 'editEmail',
          },
        ]}
      />
      <Box sx={{ height: theme.spacing(2) }} />
      <InformationBlock<Product['id']>
        title={translations.invoicing}
        footer={[
          {
            variant: 'outlined',
            label: translations.restore,
            onClick: () => {
              const {
                active,
                delegate_payment_to_employee,
                emission_report_enabled,
                enable_travel_cards_by_default,
                payment_instruments_allowed,
                reservations_notifications_enabled,
                kilometer_registration_enabled,
                outside_office_hours,
                top_spenders_enabled,
                employee_dashboard_enabled,
              } = organisationData?.result ?? {};

              setPaymentToEmployee(!!delegate_payment_to_employee);
              setActive(!!active);
              setEmissionReportAccess(!!emission_report_enabled);
              setTravelCard(!!payment_instruments_allowed);
              setKilometerRegistration(!!kilometer_registration_enabled);
              setTravelCardsByDefault(!!enable_travel_cards_by_default);
              setReservationsNotifications(!!reservations_notifications_enabled);
              setOutsideOfficeHours(!!outside_office_hours);
              setTopSpendersEnabled(!!top_spenders_enabled);
              setEmployeeDashboardEnabled(!!employee_dashboard_enabled);
              setMonthlyInvoicedProducts(monthlyInvoicedProducts);
            },
            disabled: !hasNewInvoicing,
          },
          {
            label: translations.save,
            onClick: async () => {
              setLoading('invoicing');
              try {
                await mutateAsyncEditOrganisation({
                  id: organisationData?.result.id ?? '',
                  data: {
                    coc_number: organisationData?.result.coc_number ?? '',
                    contact: {
                      email: organisationData?.result.contact?.email ?? '',
                      first_name: organisationData?.result.contact?.first_name ?? '',
                      last_name: organisationData?.result.contact?.last_name ?? '',
                      phone_number: organisationData?.result.contact?.phone_number ?? '',
                    },
                    name: organisationData?.result.name ?? '',
                    vat_number: organisationData?.result.vat_number ?? '',
                    stripe_customer_id: organisationData?.result.stripe_customer_id ?? '',
                    community_id: organisationData?.result.community?.id ?? '',
                    products: monthlyInvoicedProducts,
                    booking_note_enabled: bookingNote,
                    transaction_note_enabled: bookingNote,
                    allow_payment_instruments: travelCard,
                    kilometer_registration_enabled: kilometerRegistration,
                    emission_report_enabled: emissionReportAccess,
                    reservations_notifications_enabled: reservationsNotifications,
                    delegate_payment_to_employee: paymentToEmployee,
                    active: active,
                    enable_travel_cards_by_default: travelCardsByDefault,
                    outside_office_hours: outsideOfficeHours,
                    top_spenders_enabled: topSpendersEnabled,
                    employee_dashboard_enabled: employeeDashboardEnabled,
                  },
                });
                addToast({ title: translations.editInvoicingSuccess, severity: 'success' });
              } catch (error) {
                setLoading(false);
              }
            },
            disabled: !hasNewInvoicing,
            loading: loading === 'invoicing',
          },
        ]}
        items={[
          {
            label: translations.active,
            data: {
              value: active,
              onChange: setActive,
            },
            type: 'switch',
          },
          {
            label: translations.paymentToEmployee,
            data: {
              value: paymentToEmployee,
              onChange: setPaymentToEmployee,
            },
            type: 'switch',
          },
          {
            label: translations.bookingTransactionNote,
            data: {
              value: bookingNote,
              onChange: setBookingNote,
            },
            type: 'switch',
          },
          {
            label: translations.emissionReportAccess,
            data: {
              value: emissionReportAccess,
              onChange: setEmissionReportAccess,
            },
            type: 'switch',
          },
          {
            label: translations.travelCard,
            data: {
              value: travelCard,
              onChange: setTravelCard,
            },
            type: 'switch',
          },
          {
            label: translations.kilometerRegistration,
            data: {
              value: kilometerRegistration,
              onChange: setKilometerRegistration,
            },
            type: 'switch',
          },
          {
            label: translations.travelCardsByDefault,
            data: {
              value: travelCard ? travelCardsByDefault : false,
              onChange: setTravelCardsByDefault,
              disabled: id === 'new' || !travelCard,
            },
            type: 'switch',
          },
          {
            label: translations.reservationsNotifications,
            data: {
              value: reservationsNotifications,
              onChange: setReservationsNotifications,
            },
            type: 'switch',
          },
          {
            label: translations.outsideOfficeHours,
            data: {
              value: outsideOfficeHours,
              onChange: setOutsideOfficeHours,
            },
            type: 'switch',
          },
          {
            label: translations.topSpendingEmployees,
            data: {
              value: topSpendersEnabled,
              onChange: setTopSpendersEnabled,
            },
            type: 'switch',
          },
          {
            label: translations.enableEmployeeDashboard,
            data: {
              value: employeeDashboardEnabled,
              onChange: setEmployeeDashboardEnabled,
            },
            type: 'switch',
          },
          {
            label: translations.monthlyInvoicedProducts,
            fullWidth: true,
            data: {
              label: translations.monthlyInvoicedProducts,
              values: monthlyInvoicedProducts,
              options: (productData?.result ?? []).map(({ id, net_unit_price, name }) => ({
                id: id,
                label: `${name} - ${formatPrice({
                  price: net_unit_price ?? 0,
                  symbol: true,
                })} exc. VAT` as CustomString,
              })),
              onChange: setMonthlyInvoicedProducts,
              mode: 'multiple',
              disabled: id === 'new',
            },
            type: 'select_1',
          },
        ]}
      />
      <OrganisationMerchantCodes
        organisationId={organisationData?.result.id}
        transaction_rules={organisationData?.result.transaction_rules}
      />
      <OrganisationAfas
        organisationId={organisationData?.result.id}
        connections={organisationData?.result.connections}
      />

      {!!organisationData && (
        <OrganisationCommissions
          id={organisationData?.result.id}
          commissions={organisationData?.result?.commissions}
        />
      )}
    </>
  );

  const ProviderContent = (
    <>
      {!!organisationData && (
        <OrganisationProviders
          organisationId={organisationData?.result.id}
          providersData={organisationData?.result?.providers_data ?? {}}
        />
      )}
    </>
  );

  return (
    <Page
      name="organisation"
      loading={
        (organisationData === undefined && id !== 'new') ||
        VATData === undefined ||
        communitiesData === undefined
      }
      footer={id === 'new' ? footer : undefined}
      actions={
        id !== 'new'
          ? [
              {
                svgName: 'fileDownload',
                title: translations.export,
                mode: 'closeAfterLoading',
                onClick: async () => {
                  try {
                    const blob = await mutateAsyncExportOrganisation({
                      id: organisationData?.result.id ?? '',
                    });
                    downloadBlob({ blob, fileName: 'organisation_export' });
                  } catch (error) {
                    // Do nothing
                  }
                },
                loading: organisationData === undefined || isLoadingEditExportOrganisation,
              },
              {
                svgName: 'restore',
                title: translations.resendInvites,
                mode: 'closeOnClick',
                onClick: () => {
                  setOpenResendInvitesTitle(true);
                },
              },
              {
                svgName: 'fileDownload',
                title: translations.downloadBulkInvites,
                mode: 'closeOnClick',
                disabled: !hasBulkInvites,
                onClick: async () => {
                  try {
                    const blob = await mutateAsyncDownloadBulkInvites({
                      id: organisationData?.result.id ?? '',
                    });
                    downloadBlob({ blob, fileName: 'bulk_invites' });
                  } catch (error) {
                    // Do nothing
                  }
                },
              },
              {
                svgName: 'fileUpload',
                title: translations.uploadUserXlsx,
                mode: 'fileUpload',
                extension: '.xlsx',
                onFile: async (file) => {
                  const organisation_id = organisationData?.result.id ?? '';
                  try {
                    await mutateBulkInviteUpload({ organisation_id, file });
                  } catch (e) {
                    // Do nothing
                  }
                  addToast({ title: translations.uploadSuccessful, severity: 'success' });
                },
              },
              {
                svgName: 'launch',
                title: translations.impersonate,
                mode: 'closeOnClick',
                onClick: async () => {
                  try {
                    const organisation_id = organisationData?.result.id ?? '';

                    mutateAsyncAddSuperAdminsToOrganisation({ id: organisation_id });

                    addToast({
                      title: translations.openedLink,
                      severity: 'success',
                    });

                    window.open(getSuperAdminDashboardUrl(), '_blank');
                  } catch (error) {
                    addToast({ error, severity: 'error' });
                  }
                },
              },
              {
                svgName: 'add',
                title: translations.addUser,
                mode: 'closeOnClick',
                onClick: () => {
                  setAddUserFirstName('');
                  setAddUserLastName('');
                  setAddUserPhoneNumber('');
                  setAddUserEmail('');
                  setOpenAddUser(true);
                },
              },
            ]
          : undefined
      }
    >
      <>
        <Dialog
          title={translations.dialogResendInvitesTitle}
          footer={[
            {
              variant: 'outlined',
              label: translations.cancel,
              onClick: () => {
                setOpenResendInvitesTitle(false);
              },
              disabled: false,
            },
            {
              variant: 'contained',
              label: translations.confirm,
              onClick: async () => {
                try {
                  await mutateAsyncResendInvites({ id });
                  setOpenResendInvitesTitle(false);
                  addToast({ title: translations.dialogResendInvitesSuccess, severity: 'success' });
                } catch (error) {
                  setOpenResendInvitesTitle(false);
                }
              },
              loading: isLoadingResendInvites,
            },
          ]}
          open={openResendInvitesTitle}
          onChange={setOpenResendInvitesTitle}
        >
          <Text>{translations.dialogResendInvitesMessage}</Text>
        </Dialog>
        <Dialog
          title={translations.addUser}
          footer={[
            {
              variant: 'outlined',
              label: translations.cancel,
              onClick: () => {
                setOpenAddUser(false);
              },
              disabled: false,
            },
            {
              variant: 'contained',
              label: translations.add,
              onClick: async () => {
                try {
                  await mutateAsyncAddUser({
                    id,
                    firstName: addUserFirstName,
                    lastName: addUserLastName,
                    phoneNumber: addUserPhoneNumber,
                    email: addUserEmail,
                  });
                  setOpenAddUser(false);
                  addToast({ title: translations.dialogAddUserSuccess, severity: 'success' });
                } catch (error) {
                  setOpenAddUser(false);
                }
              },
              loading: isLoadingAddUser,
              disabled: !addUserFirstName || !addUserLastName || !addUserEmail,
            },
          ]}
          open={openAddUser}
          onChange={setOpenAddUser}
        >
          <>
            <Box sx={{ paddingTop: 0 }}>
              <TextField
                label={translations.firstName}
                variant="outlined"
                placeholder={translations.firstName}
                value={addUserFirstName}
                onChange={setAddUserFirstName}
                fullWidth={true}
                required={true}
                type="name"
              />
            </Box>
            <Box sx={{ paddingTop: 2 }}>
              <TextField
                label={translations.lastName}
                variant="outlined"
                placeholder={translations.lastName}
                value={addUserLastName}
                onChange={setAddUserLastName}
                fullWidth={true}
                required={true}
                type="name"
              />
            </Box>
            <Box sx={{ paddingTop: 2 }}>
              <TextField
                label={translations.email}
                variant="outlined"
                placeholder={translations.email}
                value={addUserEmail}
                onChange={setAddUserEmail}
                fullWidth={true}
                required={true}
                type="email"
              />
            </Box>
            <Box sx={{ paddingTop: 2 }}>
              <TextField
                label={translations.phoneNumber}
                variant="outlined"
                placeholder={translations.phoneNumber}
                value={addUserPhoneNumber}
                onChange={setAddUserPhoneNumber}
                fullWidth={true}
                required={false}
                type="phoneNumber"
              />
            </Box>
          </>
        </Dialog>

        <TableTabs
          variant="content"
          data={[
            { label: translations.details, content: DetailsContent },
            { label: translations.providers, content: ProviderContent },
          ]}
          tabIndex={tabIndex}
          setTabIndex={(index) => {
            setSearchParams({ tab: index });
            setTabIndex(index);
          }}
        />
      </>
    </Page>
  );
}

export { OrganisationPage };
